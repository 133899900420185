const resetField = (key: string, field: KintoneField) => {
  const {
    type,
    defaultValue,
    defaultNowValue,
    openGroup,
    fields
  } = field;
  if (
    [
      'SINGLE_LINE_TEXT',
      'RADIO_BUTTON',
      'NUMBER',
      'MULTI_LINE_TEXT',
      'LINK'
    ].includes(type)) {
      return defaultValue || ""
    } else if(
      [
        'DROP_DOWN',
        'CHECK_BOX',
      ].includes(type)
    ) {
      return defaultValue === "" ? "-1": defaultValue
    } else if([ 'GROUP' ].includes(type)) {
      return openGroup
    } else if([ 'SUBTABLE' ].includes(type)) {
      const defaultTableValues: {[key: string]: any} = {
        id: 1,
        ...Object.fromEntries(
          Object.entries(fields)
          .map(([ childKey, childField ]) => {
            return [
              childKey,
              resetField(childKey, childField)
            ]
          })
        )
      };

      return [
        defaultTableValues
      ]
    } else if([ 'DATE', 'TIME', 'DATETIME' ].includes(type)) {
      return defaultNowValue ? (new Date()).toUTCString() : null
    } else {
      console.log(key, type)
    }
}

export default resetField
