import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { deepOrange } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import logo from './assets/images/logo.webp';

const ColorButton = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.getContrastText(deepOrange[500]),
  backgroundColor: deepOrange[500],
  '&:hover': {
    backgroundColor: deepOrange[100],
  },
}));

const Header: React.FC = () => {
  return (
    <AppBar
      position="sticky"
      enableColorOnDark
      sx={{
        backgroundColor: 'rgba(0,0,0,0.9)',
        paddingY: { xs: 0, sm: 2 },
        height: { xs: 80, sm: 100 }
      }}
    >
      <Toolbar
      >
        <Grid container
          sx={{
            height: 1,
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} sm={9}
            sx={{
              height: 1,
              display: 'flex',
              alignItems: 'center',
              padding: { xs: 1, sm: 0 }
            }}
          >
            <img
              src={logo}
              alt="logo"
              loading="lazy"
              height="70%"
            />
            <Link to="/">
              <Typography
                variant="h5"
                component="div"
                px={5}
                sx={{
                  fontFamily: 'Zen Maru Gothic',
                  fontSize: { xs: 22, sm: 40 },
                  color: 'rgba(255, 255, 255, 1)',
                }}
              >
                行政書士法人小金丸
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={0} sm={3}>
            <ColorButton
              variant="contained"
              href="/menu"
              sx={{
                display: { xs: 'none', sm: 'block' },
              }}
            >
              ディーラー様向け<br />
              WEBシステムログイン
            </ColorButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;