import _ from 'lodash'; // TODO:  Delete 'toNumber'!!!
import React, { createContext, useState, useEffect } from 'react'
import axios from '../plugins/axios'
import KintoneValidation, { KintoneOneRequiredValidation } from '../components/useKintoneValidation';
import resetField from '../components/resetKintoneField'
import { Auth } from 'aws-amplify';

export const KintoneContext = createContext<any>(null);
const { Provider, Consumer } = KintoneContext;

interface KintoneContextProps {
  settings: KintoneSettings,
  customization?: (context: KintoneContext) => Promise<{
    onLoad: () => void,
    onReset: () => void,
    onRestore: (fieldValues: any) => void,
  }>
}

export const KintoneContextProvider: React.FC<KintoneContextProps> = ({ children, settings: KintoneSettings, customization: KintoneCustomization }) => {
  const [layout, setLayout] = useState<Array<KintoneLayout>>([]);
  const [fields, setFields] = useState<KintoneFields>({});
  const [fieldValues, setFieldValue] = useState<{ [key: string]: any }>({});
  const [fieldErrors, setFieldErrors] = useState<KintoneErrors>({});
  const [send, setSend] = useState<boolean>(false);
  const [anotherApps, setAnotherApps] = useState<KintoneAnotherApps<Array<{ [key: string]: any }>>>({});
  const [snackMassage, setSnackMessage] = useState<AppSnackBarPayload>({ severity: 'success', message: '' });
  const [calledInitial, setCalledInitial] = useState(false);
  const [draft, setDraft] = useState<boolean>(false);
  const [changedDraft, setChangedDraft] = useState<boolean>(true);
  const [draftList, setDraftList] = useState<any[]>([]);
  const [selectedDraftKey, setSelectedDraftKey] = useState<string>("");
  const [customization, setCustomization] = useState<any>(null)

  const initial = (user: any) => {
    if (calledInitial) {
      return;
    }

    Promise.all([
      axios({
        method: "get",
        url: "/kintone/app/form/layout.json",
        headers: {
          Authorization: `Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}`
        },
        params: {
          app: KintoneSettings.appId,
        }
      })
        .then(({ data: { layout } }) => {
          return layout
        }),
      axios({
        method: "get",
        url: "/kintone/app/form/fields.json",
        headers: {
          Authorization: `Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}`
        },
        params: {
          app: KintoneSettings.appId,
        }
      })
        .then(({ data: { properties } }) => {
          return properties
        })
    ])
      .then(([layoutData, fieldsData]) => {
        if (KintoneSettings.excludeFields.length) {
          layoutData = layoutData.filter((item: KintoneLayout) => {
            if (item.type !== "ROW" && KintoneSettings.excludeFields.includes(item.code)) {
              return false;
            }
            if (item.layout) {
              item.layout.forEach((children) => {
                children.fields = children.fields.filter(({ code }) => KintoneSettings.excludeFields.includes(code) === false)
              })
            }
            if (item.fields) {
              item.fields = item.fields.filter(({ code }) => KintoneSettings.excludeFields.includes(code) === false)
            }
            return true;
          })
        }
        if (KintoneSettings.overwrite) {
          const {
            overwrite,
            required,
            settings
          } = KintoneSettings

          fieldsData = Object.fromEntries(
            Object.entries(fieldsData)
              .filter((field: any) => {
                const [
                  key, // Delete this!!!
                  value
                ]: [
                    key: string,
                    value: KintoneField
                  ] = field;

                if (!KintoneSettings.excludeFields.length) {
                  return true;
                }
                if (KintoneSettings.excludeFields.includes(value.code)) {
                  return false;
                }

                if (value.fields) {
                  value.fields = Object.fromEntries(
                    Object.entries(value.fields)
                      .filter((childField) => {
                        const [
                          key,
                          value
                        ] = childField;

                        if (KintoneSettings.excludeFields.includes(value.code)) {
                          return false;
                        }
                        return true;
                      })
                  )
                }
                return true;
              })
              .map((field: any) => {
                const [
                  key,
                  value
                ]: [
                    key: string,
                    value: KintoneField
                  ] = field;

                if (value.fields) {
                  value.fields = Object.fromEntries(
                    Object.entries(value.fields)
                      .map((childField) => {
                        const [
                          key,
                          value
                        ] = childField;

                        return [
                          key,
                          Object.assign({}, value, {
                            required: settings.validation && required.includes(key)
                          })
                        ]
                      })
                  )
                }

                return [
                  //オブジェクトを複製している。その際valueの値と
                  key,
                  Object.assign({}, value, {
                    required: settings.validation && required.includes(key)
                  })
                ]
              })
              .map((field: any) => {
                const [
                  key,
                  value
                ]: [
                    key: string,
                    value: KintoneField
                  ] = field;

                if (overwrite.hasOwnProperty(key) === false) {
                  return field;
                }

                return [
                  key,
                  _.merge(value, overwrite[key])
                ]
              })
          )
        }

        setFields(fieldsData)
        setLayout(layoutData)
        setCalledInitial(true);
        if (KintoneCustomization) {
          KintoneCustomization(providingValue).then(setCustomization)
        }
      })
  };

  const getApp = async (appId: string) => {
    const { data } = await axios({
      method: "get",
      url: "/kintone/app.json",
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      params: {
        id: appId
      }
    })
    return data;
  }

  const getFields = async (appId: string) => {
    const {
      data: {
        properties
      }
    } = await axios({
      method: "get",
      url: "/kintone/app/form/fields.json",
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      params: {
        app: appId
      }
    });
    return properties;
  }

  const getRecords = async (appId: string, fields: Array<string>, query: string = "") => {
    const {
      data: {
        totalCount,
        id
      }
    } = await axios({
      method: "post",
      url: "/kintone/records/cursor.json",
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      data: {
        app: appId,
        fields: fields,
        query: query
      }
    });

    return {
      totalCount: Number(totalCount),
      async *[Symbol.asyncIterator]() {
        while (true) {
          const {
            data: {
              records,
              next
            }
          } = await axios({
            method: "get",
            url: "/kintone/records/cursor.json",
            headers: {
              Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
            },
            params: {
              id: id
            }
          });
          yield records;
          if (next === false) {
            break;
          }
        }
      }
    }
  }

  //ここからバリデーション処理

  const validation = (values: any, parentFields: KintoneFields) => {
    let error = false;
    const {
      settings
      //kintone-configファイルで設定したバリデーションを有効にするかどうかを取得する。例：validation:true
    } = KintoneSettings;
    return [
      Object.fromEntries(
        Object.entries(values)
          .filter(fieldValue => {
            const [key,]: any = fieldValue;
            return [
              ...KintoneSettings.excludeFields,
              "id"
            ].includes(key) === false && [
              'GROUP'
            ].includes(parentFields[key]?.type) === false
          })
          .map(fieldValue => {
            const [key, value]: any = fieldValue;
            const {
              type,
              fields: fieldChilds
            } = parentFields[key];
            //console.log(value)
            //fieldsはアプリの方から取得したフィールド名やラベルなどの情報が全て入ったもの
            const kintoneValidation = KintoneValidation(parentFields[key], value);
            //components/useKintoneValidationのrequired()をkintoneValidationと定義している
            const {
              oneRequiredFields,
              dependedRequiredFields
            } = KintoneSettings;
            //console.log("oneRequiredFields:" + oneRequiredFields);
            //どちらか片方を選択する設定を取得
            //console.log("dependedRequiredFeilds:" + JSON.stringify(dependedRequiredFields));
            //depemdingValueとdepemdingFieldの設定項目を全部取得
            if (type === 'SUBTABLE') {
              return [
                key, [
                  ...value.map((r: any) => {
                    const [
                      errorsArr,
                      err
                    ] = validation(r, fieldChilds);
                    if (err) {
                      error = true;
                    }
                    return errorsArr
                  })
                ]
              ]
            }
            if (oneRequiredFields) {
              const includedItem = oneRequiredFields
                .find(item => item.includes(key));
              if (includedItem) {
                const args = includedItem.map(k => {
                  return [
                    fields[k],
                    values[k]
                  ]
                })
                const res = KintoneOneRequiredValidation(args);
                if (res) {
                  error = true;
                }
                return [
                  //ここで生成される配列の例：["car_no1",{"error":false,Massage:"一方は必須です"}]
                  key, {
                    error: res,
                    message: res ? "一方は必須です" : ""
                  }
                ]
              }
            }
            if (dependedRequiredFields && dependedRequiredFields[key]) {
              const {
                dependingField,
                dependingValue,
              } = dependedRequiredFields[key];
              //kintone-configのjsonファイルで設定したdependingValueとdependingFieldを取得している
              // console.log(dependingField)
              // console.log(dependingValue)
              let isSameValue;
              // console.log(fields[dependingField].type)
              switch (fields[dependingField].type) {
                case 'CHECK_BOX':
                  isSameValue = fieldValues[dependingField].length === dependingValue.length &&
                    fieldValues[dependingField].every((v: string) => dependingValue.includes(v))
                  break;
                case 'RADIO_BUTTON':
                  isSameValue = dependingValue.includes(fieldValues[dependingField])
                  console.log(fieldValues[dependingField])
                  break;
                default:
                  isSameValue = fieldValues[dependingField] === dependingValue;
                //ここはif文ね
              }
              // console.log(dependingValue.includes(value))
              // console.log(convertToKintoneFormat(fieldValues, fields))
              if (!isSameValue) {
                return [
                  key, {
                    error: false,
                    message: ""
                  }
                ]
              }
              if (kintoneValidation.required()) {
                error = true;//ここだ
                return [
                  key, {
                    error: true,
                    message: "必須です"
                  }
                ]
              }
            }
            if (kintoneValidation.required()) {
              error = true;//ここだ★
              return [
                key, {
                  error: true,
                  message: "必須です"
                }
              ]
            }
            return [
              key, {
                error: false,
                message: ""
              }
            ]
          })
      ),
      settings.validation && error
    ]
  }

  const convertToKintoneFormat = (fieldValues: any, fields: any) => {
    const {
      automaticFields
    } = KintoneSettings;

    return Object.fromEntries(
      Object.entries(fieldValues)
        .filter(fieldValue => {
          const [key,]: any = fieldValue;

          return [
            ...KintoneSettings.excludeFields,
            "id"
          ].includes(key) === false && [
            'GROUP'
          ].includes(fields[key]?.type) === false
        })
        .map(fieldValue => {
          const [key, value]: any = fieldValue;
          let tmpValue: any = undefined;

          switch (fields[key].type) {
            case 'SUBTABLE':
              tmpValue = value.map((t: any) => {
                return {
                  value: convertToKintoneFormat(t, fields[key].fields)
                }
              })
              break;
            case 'DATETIME':
              if (value === null) {
                tmpValue = null
              } else {
                tmpValue = (new Date(value)).toISOString()
              }
              break;
            case 'DATE':
              if (value === null) {
                tmpValue = null
              } else {
                tmpValue = new Intl.DateTimeFormat("ja-JP", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit"
                })
                  .format(new Date(value))
                  .replace(/\//g, '-')
              }
              break;
            case 'TIME':
              if (value === null) {
                tmpValue = null
              } else {
                tmpValue = new Intl.DateTimeFormat("ja-JP", {
                  hour: "2-digit",
                  minute: "2-digit"
                })
                  .format(new Date(value))
              }
              break;
            case 'DROP_DOWN':
              if (value === '-1') {
                tmpValue = ''
              } else {
                tmpValue = value
              }
              break;
            default:
              tmpValue = value
          }

          if (fields[key].lookup) {
            tmpValue = undefined;
          }
          if (fields[key].lookup && value !== "") {
            tmpValue = value;
          }
          if (automaticFields.hasOwnProperty(key)) {
            const key2: string = automaticFields[key].find(k => {
              switch (fields[k].type) {
                case 'DATETIME':
                case 'DATE':
                case 'TIME':
                  return fieldValues[k] !== null
                case 'DROP_DOWN':
                  return fieldValues[k] !== '-1'
                default:
                  return fieldValues[k] !== ''
              }
            }) as string;
            tmpValue = fieldValues[key2];
          }

          return [
            key,
            {
              value: tmpValue
            }
          ]
        })
    )
  }

  const sendMailToUser = async (id: string) => {
    await axios({
      method: "post",
      url: "/sendMail",
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      data: {
        app: KintoneSettings.appId,
        id,
        fieldValues
      }
    })
  }

  const sendDataToKintone = async () => {
    axios({
      method: "post",
      url: "/kintone/record.json",
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      data: {
        app: KintoneSettings.appId,
        record: convertToKintoneFormat(fieldValues, fields)
      }
    })
      .then(({ data: { id } }) => {
        return sendMailToUser(id)
      })
      .then(() => {
        setSnackMessage({
          severity: 'success',
          message: '送信に成功しました'
        })
        localStorage.removeItem(selectedDraftKey)
        setSelectedDraftKey("")
        setChangedDraft(true)
      })
      .catch((error) => {
        // console.log(error.response.data)
        // console.log(convertToKintoneFormat(fieldValues, fields))
        setSnackMessage({
          severity: 'error',
          message: 'エラー: ' + error.response.data.message
        })
      })
      .finally(() => {
        setSend(false)
      })
  }

  const saveAsDraft = (fieldValues: any) => {
    localStorage.setItem("kintone-app-" + KintoneSettings.appId + "-" + Date.now().toString(), JSON.stringify(fieldValues))
    setChangedDraft(true)
  }

  const providingValue: KintoneContext = {
    initial,
    layout,
    fields,
    fieldErrors,
    snackMassage,
    setSnackMessage,
    hidden: (key: string) => {
      const {
        visibility
      } = KintoneSettings;

      if (visibility === undefined) {
        return false;
      }
      if (key === undefined) {
        return false;
      }
      if (visibility[key] === undefined) {
        return false;
      }
      if (typeof visibility[key] === 'boolean') {
        return !visibility[key] as boolean;
      } else if (typeof visibility[key] === 'object') {
        const { fieldCode, fieldValue } = visibility[key] as any;
        if (fields[fieldCode].type === 'CHECK_BOX') {
          return fieldValues[fieldCode].length !== fieldValue.length ||
            !fieldValues[fieldCode].every((v: string) => fieldValue.includes(v))
        } else {
          return !fieldValue.includes(fieldValues[fieldCode])
        }
      } else {
        return false;
      }
    },
    setSend: () => {
      setSend(true);
    },
    setLayout: (newData: KintoneLayout[]) => {
      setLayout(() => {
        return Array.from(newData)
      })
    },
    setFields: (newData: KintoneFields) => {
      setFields(() => {
        return Object.assign({}, newData)
      })
    },
    getFieldValue: (key: string) => {
      return fieldValues[key];
    },
    setFieldValue: (key: string, value: any) => {
      const {
        autoCopyingBySpecificValue,
        autoClearingBySpecificValue
      } = KintoneSettings;
      setFieldValue((prev) => {
        if (autoCopyingBySpecificValue.hasOwnProperty(key)) {
          const {
            dependedValue,
            copyingFields
          } = autoCopyingBySpecificValue[key]
          copyingFields.filter(({ from, to }) => {
            return fields[from].type === 'SINGLE_LINE_TEXT' && fields[to].type === 'SINGLE_LINE_TEXT'
          })
            .forEach(({ from, to }) => {
              if (fields[key].type === 'CHECK_BOX') {
                prev[to] = value.length === dependedValue.length &&
                  dependedValue.every((v: string) => value.includes(v)) ? prev[from] : ''
              } else {
                prev[to] = dependedValue.includes(value) ? prev[from] : ''
                // console.log("from:" + JSON.stringify(prev[from]))
                // console.log("to:" + JSON.stringify(prev[to]))
              }
            })
        }
        if (autoClearingBySpecificValue.hasOwnProperty(key)) {
          const {
            dependedValue,
            clearFields
          } = autoClearingBySpecificValue[key]
          let isSameValue;
          if (fields[key].type === 'CHECK_BOX') {
            isSameValue = value.length === dependedValue.length &&
              dependedValue.every((v: string) => value.includes(v))
          } else {
            isSameValue = dependedValue.includes(value)
          }
          if (isSameValue) {
            clearFields.forEach(item => {
              if (!fields[item]) {
                return;
              }
              prev[item] = resetField(item, fields[item])
            })
          }
        }
        return {
          ...prev,
          [key]: value
        }
      })
    },
    setTableFieldValue: (key: string, tableRow: number, columnName: string, value: any) => {
      setFieldValue((prev) => {
        prev[key][tableRow][columnName] = value;
        return {
          ...prev
        }
      })
    },
    addTableRecord: (key: string) => {
      const newRecord: any = {};
      for (const [
        fieldName, {
          type,
          defaultValue,
          defaultNowValue,
        }
      ] of Object.entries(fields[key].fields)) {
        if (
          [
            'SINGLE_LINE_TEXT',
            'RADIO_BUTTON',
            'NUMBER',
            'MULTI_LINE_TEXT',
            'LINK'
          ].includes(type)) {
          newRecord[fieldName] = defaultValue || ""
        } else if (['DATE', 'TIME', 'DATETIME'].includes(type)) {
          newRecord[fieldName] = defaultNowValue ? (new Date()).toUTCString() : null;
        } else if (['DROP_DOWN', 'CHECK_BOX'].includes(type)) {
          newRecord[fieldName] = defaultValue === "" ? "-1" : defaultValue;
        } else { }
      }
      setFieldValue((prev) => {
        newRecord.id = prev[key].length + 1;
        prev[key].push(newRecord);
        return {
          ...prev
        }
      })
    },
    deleteTableRecord: (key: string, id: number) => {
      setFieldValue((prev) => {
        prev[key] = prev[key]
          .filter((r: any) => r.id !== id)
          .map((r: any, i: number) => {
            r.id = i + 1;
            return r;
          })
        return {
          ...prev
        }
      })
    },
    setFieldValues: (newData: { [key: string]: any }) => {
      setFieldValue((prev) => {
        return {
          ...prev,
          ...newData
        }
      })
    },
    resetAllFieldsValues: (user: any) => {
      const {
        autoInsertingByCognito
      } = KintoneSettings
      const defaultValues = Object.fromEntries(
        Object.entries(fields)
          .filter(([, { type }]) => [
            'SINGLE_LINE_TEXT',
            'RADIO_BUTTON',
            'NUMBER',
            'MULTI_LINE_TEXT',
            'LINK',
            'DROP_DOWN',
            'CHECK_BOX',
            'GROUP',
            'SUBTABLE',
            'DATE',
            'TIME',
            'DATETIME'
          ].includes(type))
          .map(([key, field]) => {
            if (user.attributes && autoInsertingByCognito.hasOwnProperty(key)) {
              const attributeName = autoInsertingByCognito[key];
              return [
                key,
                user.attributes[attributeName]
              ]
            }
            return [
              key,
              resetField(key, field)
            ]
          })
          .filter((([key,]) => key))
      )
      setFieldValue(() => {
        return {
          ...defaultValues
        }
      })
      setFieldErrors({})
      if (customization) {
        customization.onReset();
      }
    },
    getAnotherApp: async (appId, fields, query = ""): Promise<KintoneAnotherApp<Array<{ [key: string]: any }>>> => {
      if (anotherApps[appId] !== undefined) {
        return anotherApps[appId];
      } else {
        const recordsIterator = await getRecords(appId, fields, query)
        let arr: any = []
        for await (const items of recordsIterator) {
          arr = arr.concat(items)
        }

        const app = {
          fields: await getFields(appId),
          app: await getApp(appId),
          records: arr,
          totalCount: recordsIterator.totalCount
        }
        setAnotherApps(prev => {
          return {
            ...prev,
            [appId]: app
          }
        });
        return app;
      }
    },
    getError: (code: string) => {
      if (fieldErrors[code]) {
        return fieldErrors[code];
      } else {
        return {
          error: false,
          message: ""
        }
      }
    },
    getGroupError: (groupCode) => {
      const layoutRow = layout.find(({ code }: { code: string }) => code === groupCode);
      if (layoutRow === undefined) {
        return false;
      }
      return layoutRow.layout.some(child => {
        return child.fields.some((field: KintoneLayoutField) => {
          if (fieldErrors[field.code] === undefined) {
            return false;
          }
          return fieldErrors[field.code].error
        })
      })
    },
    draftList,
    setDraft,
    restoreDraft: (key) => {
      const draftData = localStorage.getItem(key) as string;
      setFieldValue({
        ...JSON.parse(draftData)
      })
      setSelectedDraftKey(key)
      if (customization) {
        customization.onRestore(JSON.parse(draftData))
      }
    },
    deleteDraft: (key) => {
      localStorage.removeItem(key)
      setChangedDraft(true)
    }
  }

  useEffect(() => {
    if (!calledInitial) return;
    if (customization) {
      customization.onLoad();
    }
  }, [
    calledInitial,
    customization
  ])

  useEffect(() => {
    if (!send) return;
    const [
      errorsArr,
      error
    ] = validation(fieldValues, fields);
    // console.log(validation(fieldValues, fields))
    //console.log("error:" + error)
    //console.log("errorsArr:" + JSON.stringify(fieldErrors))バリデーションが失敗してるやつ
    setFieldErrors({
      ...errorsArr
    })
    if (!error) {
      sendDataToKintone();
    } else {
      setSend(false)
      setSnackMessage({
        severity: 'error',
        message: '入力されていない項目があるか、正しくない値が入力されています。'
      })
    }
  }, [
    send
  ])

  React.useEffect(() => {
    if (!draft) return;
    saveAsDraft(fieldValues);
    setDraft(false)
  }, [
    draft
  ])

  React.useEffect(() => {
    if (!changedDraft) return;
    setDraftList([
      ...Object
        .keys(localStorage)
        .filter(key => key.includes('kintone-app-' + KintoneSettings.appId))
        .sort((a, b) => Number(b.split('-').pop()) - Number(a.split('-').pop()))
        .map(key => {
          const timestamp = key.split('-').pop() as string
          return {
            key,
            timestamp
          }
        })
    ])
    setChangedDraft(false)
  }, [
    changedDraft
  ])

  return (
    <Provider value={providingValue}>
      {children}
    </Provider>
  )
}
export {
  Consumer as KintoneConsumer
}
