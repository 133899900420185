import React from 'react';
import './topPage.css';
import logo from './assets/images/logo.webp';
import topImage from './assets/images/top-image.webp';
import torophy from './assets/images/torophy.webp';
import network from './assets/images/network.webp';
import speed from './assets/images/speed.webp';
import construction from './assets/images/square_construction.webp';
import lotus from './assets/images/square_lotus.webp';
import passport from './assets/images/square_passport.webp';
import car from './assets/images/square_car.webp';
import star from './assets/images/star.webp';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { styled } from '@mui/material/styles';
import recruitImage from './assets/images/recruitImage.png';
import { text } from '@fortawesome/fontawesome-svg-core';
import { textAlign } from '@mui/system';

const TopImageTypography = styled(Typography)(() => ({
  fontSize: '3rem',
  fontFamily: 'Zen Maru Gothic',
  letterSpacing: 30,
  lineHeight: "200%"
}));

const TopPage: React.FC = () => {
  return (
    <Box>
      <Paper
        sx={{
          backgroundImage: 'url(' + topImage + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vh',
          marginTop: '100',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white'
        }}
      >
        <Box display="grid" gap={3}>
          <Box
            sx={{
              writingMode: 'vertical-lr',
              margin: 'auto',
            }}
          >
            <TopImageTypography
              sx={{
                fontSize: '2rem',
                textAlign: 'right',
                textShadow: '3px 3px 3px rgba(13,1,1,0.7)'
              }}
            >
              小金丸
            </TopImageTypography>
            <TopImageTypography
              sx={{
                fontSize: '2rem',
                textShadow: '3px 3px 3px rgba(13,1,1,0.7)'
              }}
            >
              行政書士法人
            </TopImageTypography>
          </Box>
          <Box
            sx={{
              margin: 'auto',
              width: { xs: "20%", sm: "10%" }
            }}
          >
            <img
              src={logo}
              alt="logo"
              loading="lazy"
              width="100%"
            />
          </Box>
          <Box>
            <Typography variant="h4" sx={{
              textAlign: 'center',
              fontSize: { xs: 20, sm: 30 },
              marginBottom: "30px",
              textShadow: '3px 3px 3px rgba(13,1,1,1)',
              display: { xs: 'noen', sm: 'block' },
            }}
            >
              Administratve Scrivener Koganemaru Office<br />
              <br />
              自動車登録・車庫証明・出張封印のことならお任せください
            </Typography>
          </Box>
          {/* <Box
            sx={{
              height: '45%',
              textAlign: 'center'
            }}
          >
            <a href="https://en-gage.net/koganemarukyujin_saiyo/?banner=1" target="_blank" rel="noreferrer noopener">
              <img src={recruitImage} alt="engage" height="100%" />
            </a>
          </Box> */}
        </Box>
      </Paper>
      <Container>
        <Box
          my={3}
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#004696'
            }}
          >
            選ばれる理由
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card >
              <CardMedia
                component="img"
                image={torophy}
                alt="green iguana"
              />
              <CardContent>
                <Typography variant="h5">
                  経験と実績
                </Typography>
                <Typography>
                  自動車登録業界において経験豊富なスタッフがお客様のビジネスをサポートします。
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card >
              <CardMedia
                component="img"
                image={network}
                alt="green iguana"
              />
              <CardContent>
                <Typography variant="h5">
                  全国対応可能
                </Typography>
                <Typography>
                  全国の行政書士ネットワーク、OSSの活用によりワンストップで県外登録・車庫証明・出張封印に対応いたします。
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card >
              <CardMedia
                component="img"
                image={star}
                alt="green iguana"
              />
              <CardContent>
                <Typography variant="h5">
                  懇切丁寧に対応
                </Typography>
                <Typography>
                  ご依頼いただいたお仕事ひとつひとつを大切に、私たちをお選びいただいたお客様の信頼に応えます。
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card >
              <CardMedia
                component="img"
                image={speed}
                alt="green iguana"
              />
              <CardContent>
                <Typography variant="h5">
                  スピーディーな対応
                </Typography>
                <Typography>
                  事務所は、福岡運輸支局に隣接した福岡交通会館内にあり、<br />
                  訪問しやすく、スピーディーに対応が可能です。
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box
          my={3}
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#004696'
            }}
          >
            業務内容
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <ImageListItem sx={{
              background: 'rgb(149, 169, 207)',
            }}
            >
              <img
                src={car}
                alt="achievement"
                loading="lazy"
              />
              <ImageListItemBar
                title="自動車登録・車庫証明、出張封印"
                sx={{
                  fontSize: 300
                }}
              />
            </ImageListItem>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ImageListItem sx={{
              background: 'rgb(149, 169, 207)'
            }}
            >
              <img
                src={lotus}
                alt="achievement"
                loading="lazy"
              />
              <ImageListItemBar
                title="相続・遺言"
              />
            </ImageListItem>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ImageListItem sx={{
              background: 'rgb(149, 169, 207)'
            }}
            >
              <img
                src={construction}
                alt="achievement"
                loading="lazy"
              />
              <ImageListItemBar
                title="建設業許可"
              />
            </ImageListItem>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ImageListItem sx={{
              background: 'rgb(149, 169, 207)'
            }}
            >
              <img
                src={passport}
                alt="achievement"
                loading="lazy"
              />
              <ImageListItemBar
                title="外国人の在留許可（VISA)"
              />
            </ImageListItem>
          </Grid>
        </Grid>
        <Box
          my={3}
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#004696'
            }}
          >
            アクセスマップ
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            overflow: 'auto',
            height: { xs: 300, sm: 600 }
          }}
        >
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.2248920260754!2d130.4343458151537!3d33.651334246197735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35418f1b86396a95%3A0x141088655ef56361!2z5bCP6YeR5Li46KGM5pS_5pu45aOr5LqL5YuZ5omA!5e0!3m2!1sja!2sjp!4v1642104468781!5m2!1sja!2sjp'
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='video'
            width="100%"
            height="100%"
          />
        </Box>
      </Container >
    </Box >
  );
}

export default TopPage;