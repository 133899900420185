import React from 'react';
import { withAuthenticator, Heading, useTheme } from '@aws-amplify/ui-react';

const options = {
  components: {
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          ディーラー様向けシステム
        </Heading>
      }
    }
  }
}

export const InputPage = withAuthenticator(React.lazy(() => import("./input")), options);
export const PricesPage = withAuthenticator(React.lazy(() => import("./prices")), options);
export const MenuPage = withAuthenticator(React.lazy(() => import("./menu")), options);
