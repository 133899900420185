import React from 'react';
import { Suspense } from 'react';
import './App.css';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import Header from './header';
import Footer from './footer';
import TopPage from './topPage';
import { InputPage, PricesPage, MenuPage } from './pages/protectedPages';

import { KintoneContextProvider } from './data/KintoneContext'
import LinearProgress from '@mui/material/LinearProgress';

import KintoneSettings50 from './kintone-config-50.json'
import KintoneCustomization55 from './kintone-customization-55'
import KintoneSettings55 from './kintone-config-55.json'

const App: React.FC = () => {
  return (
    <div className="App">
      <AmplifyProvider>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={
              <Header />
              } />
          </Routes>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/" element={<TopPage />} />
              <Route path="/menu" element={<MenuPage />} />
              <Route path="/parkingSpaceCertification" element={
                <KintoneContextProvider
                  settings={KintoneSettings50}
                >
                  <InputPage />
                </KintoneContextProvider>
                }
              />
              <Route path="/vehicleRegistration" element={
                <KintoneContextProvider
                  settings={KintoneSettings55}
                  customization={KintoneCustomization55}
                >
                  <InputPage />
                </KintoneContextProvider>
                }
              />
              <Route path="/prices" element={<PricesPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
          <Routes>
            <Route path="*" element={
              <Footer />
              } />
          </Routes>
        </BrowserRouter>
      </AmplifyProvider>
    </div>
  );
}

export default App;
