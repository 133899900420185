const KintoneValidation = (...props: any) => {
  const [
    field,
    value
  ] = props;
  return {
    required(): boolean {
      if (field === undefined) {
        return false;
      }

      const {
        type,
        required
      } = field;

      if (required !== true) {
        // console.log(required)
        return false;
      }
      if (
        [
          'DATETIME',
          'DATE',
          'TIME'
        ].includes(type) && value === null
      ) {
        // console.log("28:" + type)
        return true;
      }
      if (
        [
          'SINGLE_LINE_TEXT',
          'MULTI_LINE_TEXT',
          'NUMBER',
          'LINK'
        ].includes(type) && value === ""
      ) {
        // console.log(type)
        // console.log("39:" + [
        //   'SINGLE_LINE_TEXT',
        //   'MULTI_LINE_TEXT',
        //   'NUMBER',
        //   'LINK'
        // ].includes(type) && value === "")
        return true;
      }
      if (
        [
          'DROP_DOWN',
        ].includes(type) && value === "-1"
      ) {
        // console.log("47:" + type)
        return true;
      }
      if (
        [
          'RADIO_BUTTON',
        ].includes(type) && value === "未選択"
      ) {
        // console.log("55:" + type)
        return true;
      }
      if (
        [
          'CHECK_BOX'
        ].includes(type) && value.length === 0
      ) {
        // console.log("63:" + type)
        return true;
      }
      // console.log("66:" + type)
      return false;
    }
  }
}

export const KintoneOneRequiredValidation = (props: any[]): boolean => {
  return props.every(item => {
    const validation = KintoneValidation(...item)
    return validation.required();
  })
}

export default KintoneValidation;
