const makingDropdownFields = async (context: KintoneContext) => {
  const {
    getAnotherApp,
    setFieldValue
  } = context;

  let select1: HTMLSelectElement = document.createElement('select');
  let select2: HTMLSelectElement = document.createElement('select');
  let bigMasterList: any[] = [];
  const {
    records,
  } = await getAnotherApp("54", [
    'big_master',
    'small_master'
  ], '')

  return {
    onLoad() {
      const spaceElement = document.getElementById("field-my_space_field");
      if (spaceElement === null) {
        return;
      }

      if (records.length === 0) {
        spaceElement.innerHTML = 'マスタアプリにレコードがありません';
        return;
      }

      const select1Box = document.createElement("div");
      const select1LabelBox = document.createElement("div");
      const select1Label = document.createElement("span");
      select1Label.textContent = '登録内容';
      select1LabelBox.appendChild(select1Label);
      select1Box.appendChild(select1LabelBox);

      select1.name = "big_cat";
      select1.id = "big_cat";

      const select2Box = document.createElement("div");
      const select2LabelBox = document.createElement("div");
      const select2Label = document.createElement("span");
      select2Label.textContent = '登録内容詳細';
      select2LabelBox.appendChild(select2Label);
      select2Box.appendChild(select2LabelBox);

      select2.name = "small_cat";
      select2.id = "small_cat";

      bigMasterList = records.map(({ big_master: { value } }: any) => {
        return value;
      })
      .filter((val: string, index: number, self: string[]) => {
        return index === self.indexOf(val);
      })

      bigMasterList.forEach((val: string, index: number, self: string[]) => {
        const option = document.createElement("option");
        option.setAttribute("value", val);
        option.textContent = val;
        select1.appendChild(option);
      })

      records.filter(({ big_master: { value } }: any) => {
        return value === bigMasterList[0]
      })
      .map(({ small_master: { value } }: any) => {
        return value;
      })
      .forEach((val: string, index: number, self: string[]) => {
        const option = document.createElement("option");
        option.setAttribute("value", val);
        option.textContent = val;
        select2.appendChild(option);
        if (index === 0) {
          setFieldValue('small_dropdown', val)
        }
      })

      select1.onchange = ({ target: { value: selectedValue } }: any) => {
        setFieldValue("big_dropdown", selectedValue)
        Array.from(select2.children).forEach(option => select2.removeChild(option));

        const smallMasterList = records.filter(({ big_master: { value } }: any) => {
          return value === selectedValue
        })
        .map(({ small_master: { value } }: any) => {
          return value;
        })

        smallMasterList.forEach((val: string, index: number, self: string[]) => {
          const option = document.createElement("option");
          option.setAttribute("value", val);
          option.textContent = val;
          select2.appendChild(option);
        })

        setFieldValue("small_dropdown", smallMasterList[0])
      }
      select2.onchange = ({ target: { value: selectedValue } }: any) => setFieldValue("small_dropdown", selectedValue)

      select1Box.appendChild(select1)
      select2Box.appendChild(select2)
      spaceElement.appendChild(select1Box)
      spaceElement.appendChild(select2Box)
      spaceElement.setAttribute('style', 'display: flex')
      select1Box.setAttribute('style', 'margin: 8px')
      select2Box.setAttribute('style', 'margin: 8px')
      setFieldValue('big_dropdown', bigMasterList[0])
    },
    onReset() {
      Array.from(select2.children).forEach(option => select2.removeChild(option));

      const smallMasterList = records.filter(({ big_master: { value } }: any) => {
        return value === bigMasterList[0]
      })
      .map(({ small_master: { value } }: any) => {
        return value;
      })

      smallMasterList.forEach((val: string, index: number, self: string[]) => {
        const option = document.createElement("option");
        option.setAttribute("value", val);
        option.textContent = val;
        select2.appendChild(option);
      })
      setFieldValue("big_dropdown", bigMasterList[0])
      setFieldValue("small_dropdown", smallMasterList[0])
    },
    onRestore(fieldValues: any) {
      Array.from(select2.children).forEach(option => select2.removeChild(option));

      const smallMasterList = records.filter(({ big_master: { value } }: any) => {
        return value === fieldValues['big_dropdown']
      })
      .map(({ small_master: { value } }: any) => {
        return value;
      })

      smallMasterList.forEach((val: string, index: number, self: string[]) => {
        const option = document.createElement("option");
        option.setAttribute("value", val);
        option.textContent = val;
        select2.appendChild(option);
      })
      select1.value = fieldValues['big_dropdown']
      select2.value = fieldValues['small_dropdown']
    }
  }

}

export default makingDropdownFields
