import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

const Footer: React.FC = () => {
  return (
    <AppBar
      component="footer"
      position="static"
      enableColorOnDark
      sx={{
        backgroundColor: '#1e1e1e',
        paddingY: 2,
      }}
    >
      <Container>
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Link
                to="/">
                <Typography
                  variant="h5"
                  component="div"
                  px={5}
                  sx={{
                    fontFamily: 'Zen Maru Gothic',
                    fontSize: { xs: 25, sm: 30 },
                    color: 'rgb(255, 255, 255)',
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderBottom: { xs: 'solid 1px', sm: 'none' }
                  }}
                >
                  行政書士法人小金丸
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  alignSelf: 'self-start',
                  marginTop: { xs: 5, sm: 0 },
                }}
              >
                <Typography variant="h6"
                  sx={{
                    fontWeight: 'bolder',
                    borderBottom: { xs: 'solid 1px', sm: 'none' }
                  }}
                >
                  お問い合わせ
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                  }}
                >
                  TEL : 092-671-4146<br />
                  FAX : 092-682-3487<br />
                  受付 : 平日8:30 - 17:30
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  alignSelf: 'self-start',
                  marginTop: { xs: 5, sm: 0 },
                }}
              >
                <Typography variant="h6"
                  sx={{
                    fontWeight: 'bolder',
                    borderBottom: { xs: 'solid 1px', sm: 'none' },
                  }}
                >
                  業務内容
                </Typography>
                <Box
                  sx={{
                    listStyle: 'none',
                  }}
                >
                  <li>&gt; 自動車登録</li>
                  <li>&gt; 車庫証明</li>
                  <li>&gt; 出張封印</li>
                  <li>&gt; 相続・遺言</li>
                  <li>&gt; 建設許可</li>
                  <li>&gt; 外国人の在留許可(VISA)</li>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
      <Box
        sx={{ textAlign: 'center' }}
        mt={3}
      >
        <Typography>
          Copyright © 2022 行政書士法人小金丸 All Rights Reserved.
        </Typography>
      </Box>
    </AppBar >
  );
}

export default Footer;
